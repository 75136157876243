<template>
  <!-- Snackbar para mostrar un mensaje -->
  <v-snackbar v-model="snackbar" :timeout="timeout" :color="color">
    {{ text }}
  </v-snackbar>
</template>

<script>
export default {
  data: function() {
    return {
      timeout: 4000,
      color: "info",
      snackbar: false,
      text: ""
    };
  },
  methods: {
    showError: function(errorMessage) {
      this.text = errorMessage;
      this.color = "error";
      this.snackbar = true;
    },
    showSuccessMessage: function(message) {
      this.text = message;
      this.color = "success";
      this.snackbar = true;
    }
  }
};
</script>

<style></style>
